import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    left?: boolean,
    right?: boolean,
    justify?: boolean,
    center?: boolean,
    fullWidth?: boolean,
    className?: string,
    label?: string,
    values?: {[key: string]: string | number | null | undefined},
    children?: any,
    error?: boolean,
    success?: boolean,
    md?: boolean,
    sm?: boolean,
    xl?: boolean,
    twoXL?: boolean,
    fourXL?: boolean,
    grey?: boolean,
    green?: boolean,
    pink?: boolean,
    bold?: boolean,
    xs?: boolean,
};

export const Text: (props: Props) => JSX.Element =
    ({
         left,
         right,
         justify,
         center,
         fullWidth,
         className,
         label,
         values,
         children,
         error,
         success,
         sm,
         md,
         xl,
         twoXL,
         fourXL,
         grey,
         green,
         pink,
         bold,
         xs
     }) => {

    let Tag = 'span';

    if (left || right || justify || center || fullWidth) {
        Tag = 'div';
    }

    let classes = '';

    if (left) classes += 'text-left ';
    if (right) classes += 'text-right ';
    if (justify) classes += 'text-justify ';
    if (center) classes += 'text-center ';
    if (error) classes += 'text-bk-red ';
    if (success) classes += 'text-bk-green ';
    if (xs) classes += 'text-xs ';
    if (md) classes += 'text-md ';
    if (sm) classes += 'text-sm ';
    if (xl) classes += 'text-xl ';
    if (twoXL) classes += 'text-2xl ';
    if (fourXL) classes += 'text-4xl ';
    if (grey) classes += 'text-bk-grey-300 ';
    if (green) classes += 'text-bk-green-700 ';
    if (pink) classes += 'text-bk-pink-200 ';
    if (bold) classes += 'font-bold ';

    return (
        // @ts-ignore
        <Tag className={classes + (className || '')}>
            {label &&
                <FormattedMessage id={label}  values={(values ? values : {})} />
            }
            {children &&
                <>{children}</>
            }
        </Tag>
    );
};
